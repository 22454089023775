import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import Tooltip from '../tooltip/tooltip';

const Button = forwardRef(
  (
    {
      title,
      icon,
      className,
      onClickHandler,
      disabled = false,
      infoData,
      type = 'button',
      variant = 'normal',
      color = 'primary',
      isMobileAddBtn = false,
    },
    ref
  ) => {
    const colors = {
      primary: {
        normal: 'bg-primary text-white [&_path]:stroke-white',
        ghost:
          'bg-transparent hover:bg-primary hover:text-white text-primary border border-primary [&_path]:stroke-primary [&_path]:hover:stroke-white',
        borderless: 'bg-transparent text-primary [&_path]:stroke-primary',
      },
      neutral: {
        normal: 'bg-gray-2 text-gray-1 [&_path]:stroke-gray-1',
        ghost:
          'bg-transparent hover:bg-gray-2 text-gray-1 border border-gray-2 [&_path]:stroke-gray-1',
        borderless: 'bg-transparent text-gray-1 [&_path]:stroke-gray-1',
      },
      error: {
        normal: 'bg-error text-white [&_path]:stroke-white',
        ghost:
          'bg-transparent hover:bg-error hover:text-white text-error border border-error [&_path]:stroke-error [&_path]:hover:stroke-white',
        borderless: 'bg-transparent text-error [&_path]:stroke-error',
      },
    };

    return (
      <>
        <button
          ref={ref}
          className={`group flex w-max items-center justify-center gap-3 rounded-full px-6 py-2 text-base font-medium transition-all [&_path]:transition-all ${disabled ? 'pointer-events-none' : ''} ${className} ${
            disabled ? colors['neutral']?.[variant] : colors[color]?.[variant]
          } ${isMobileAddBtn ? 'fixed bottom-4 right-4 z-50 h-[64px] w-[64px] md:static md:z-0 md:h-auto md:w-auto' : ''} `}
          onClick={() => onClickHandler?.()}
          type={type}
          disabled={disabled}
        >
          {icon}
          {title && (
            <span
              className={`${isMobileAddBtn ? 'sr-only md:not-sr-only' : ''}`}
            >
              {title}
            </span>
          )}
        </button>
        {infoData && (
          <Tooltip
            infoData={infoData}
            className='ml-2'
          />
        )}
      </>
    );
  }
);

Button.displayName = 'Button';

Button.propTypes = {
  title: PropTypes.string,
  icon: PropTypes.object,
  className: PropTypes.string,
  disabled: PropTypes.any,
  onClickHandler: PropTypes.func,
  infoData: PropTypes.object,
  type: PropTypes.string,
  variant: PropTypes.oneOf(['normal', 'ghost', 'borderless']),
  color: PropTypes.oneOf(['primary', 'neutral']),
};

export default Button;
