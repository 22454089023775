import React, { forwardRef, useImperativeHandle, useMemo } from 'react';
import useCommanForm from '../../../../hooks/form/useCommanForm';
import Input from '../../../atoms/input/Input';
import * as yup from 'yup';
import { Controller } from 'react-hook-form';
import MultiSelect from '../../../atoms/multiSelect/MultiSelect';
import countryData from '../../../../utils/binCardCountryList.json';

const countriesArray = countryData.countries;

const schema = yup.object().shape({
  card_brand: yup.string().nullable(),
  bin: yup.string().required(),
  card_type: yup.string().nullable(),
  card_sub_type: yup.string().nullable(),
  issuing_organisation: yup.string().nullable(),
  country: yup
    .object()
    .required('Country is required')
    .test('is-not-empty', 'Country is required', (value) => {
      return value && Object.keys(value).length > 0;
    }),
  iso2: yup
    .object()
    .required('ISO2 is required')
    .test('is-not-empty', 'ISO2 is required', (value) => {
      return value && Object.keys(value).length > 0;
    }),
  iso3: yup
    .object()
    .required('ISO3 is required')
    .test('is-not-empty', 'ISO3 is required', (value) => {
      return value && Object.keys(value).length > 0;
    }),
  iso_numeric: yup
    .object()
    .required('ISO Numeric is required')
    .test('is-not-empty', 'ISO Numeric is required', (value) => {
      return value && Object.keys(value).length > 0;
    }),
});

const CardBinsForm = forwardRef(({ onSubmit, cardBin }, ref) => {
  const countryNameOptions = useMemo(
    () =>
      countriesArray.map((country) => ({
        id: country.country,
        name: country.country,
        countryData: country,
      })),
    []
  );

  const iso2Options = useMemo(
    () =>
      countriesArray.map((country) => ({
        id: country.iso2,
        name: country.iso2,
        countryData: country,
      })),
    []
  );

  const iso3Options = useMemo(
    () =>
      countriesArray.map((country) => ({
        id: country.iso3,
        name: country.iso3,
        countryData: country,
      })),
    []
  );

  const isoNumericOptions = useMemo(
    () =>
      countriesArray.map((country) => ({
        id: country.iso_numeric,
        name: country.iso_numeric,
        countryData: country,
      })),
    []
  );

  const initialFormValues = useMemo(() => {
    if (!cardBin) return null;

    const countryOption = countryNameOptions.find(
      (option) => option.name === cardBin.country
    );
    const iso2Option = iso2Options.find(
      (option) => option.name === cardBin.iso2
    );
    const iso3Option = iso3Options.find(
      (option) => option.name === cardBin.iso3
    );
    const isoNumericOption = isoNumericOptions.find(
      (option) => option.name === cardBin.iso_numeric
    );

    return {
      ...cardBin,
      country: countryOption,
      iso2: iso2Option,
      iso3: iso3Option,
      iso_numeric: isoNumericOption,
    };
  }, [
    cardBin,
    countryNameOptions,
    iso2Options,
    iso3Options,
    isoNumericOptions,
  ]);

  const { register, handleSubmit, reset, errors, control, setValue } =
    useCommanForm(schema, initialFormValues);

  const updateFields = (countryData) => {
    const countryOption = {
      id: countryData.country,
      name: countryData.country,
      countryData,
    };
    const iso2Option = {
      id: countryData.iso2,
      name: countryData.iso2,
      countryData,
    };
    const iso3Option = {
      id: countryData.iso3,
      name: countryData.iso3,
      countryData,
    };
    const isoNumericOption = {
      id: countryData.iso_numeric,
      name: countryData.iso_numeric,
      countryData,
    };

    setValue('country', countryOption);
    setValue('iso2', iso2Option);
    setValue('iso3', iso3Option);
    setValue('iso_numeric', isoNumericOption);
  };

  useImperativeHandle(ref, () => ({
    submit: () => handleSubmit(onSubmit)(),
    reset: () => reset(),
  }));

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className='space-y-6'
    >
      <Input
        type='text'
        register={register('bin')}
        label='Bin'
        required
        error={errors?.bin?.message}
      />
      <Input
        type='text'
        register={register('card_brand')}
        label='Card Brand'
        error={errors?.card_brand?.message}
      />
      <Input
        type='text'
        register={register('card_type')}
        label='Card Type'
        error={errors?.card_type?.message}
      />
      <Input
        type='text'
        register={register('card_sub_type')}
        label='Card Subtype'
        error={errors?.card_sub_type?.message}
      />
      <Input
        type='text'
        register={register('issuing_organisation')}
        label='Organization'
        error={errors?.issuing_organisation?.message}
      />
      <Controller
        name='country'
        control={control}
        render={({ field }) => {
          return (
            <MultiSelect
              controllerField={field}
              options={countryNameOptions}
              className='text-xs'
              label='Country'
              isClearable={false}
              isRequired={true}
              error={errors?.country?.message}
              onChange={(selectedOption) => {
                field.onChange(selectedOption);
                updateFields(selectedOption.countryData);
              }}
            />
          );
        }}
      />
      <Controller
        name='iso2'
        control={control}
        render={({ field }) => (
          <MultiSelect
            controllerField={field}
            options={iso2Options}
            className='text-xs'
            label='ISO2'
            isClearable={false}
            isRequired={true}
            error={errors?.iso2?.message}
            onChange={(selectedOption) => {
              field.onChange(selectedOption);
              updateFields(selectedOption.countryData);
            }}
          />
        )}
      />
      <Controller
        name='iso3'
        control={control}
        render={({ field }) => (
          <MultiSelect
            controllerField={field}
            options={iso3Options}
            className='text-xs'
            label='ISO3'
            isClearable={false}
            isRequired={true}
            error={errors?.iso3?.message}
            onChange={(selectedOption) => {
              field.onChange(selectedOption);
              updateFields(selectedOption.countryData);
            }}
          />
        )}
      />
      <Controller
        name='iso_numeric'
        control={control}
        render={({ field }) => (
          <MultiSelect
            controllerField={field}
            options={isoNumericOptions}
            className='text-xs'
            label='ISO Numeric'
            isClearable={false}
            isRequired={true}
            error={errors?.iso_numeric?.message}
            onChange={(selectedOption) => {
              field.onChange(selectedOption);
              updateFields(selectedOption.countryData);
            }}
          />
        )}
      />
    </form>
  );
});

CardBinsForm.displayName = 'CardBinsForm';

export default CardBinsForm;
