import React, { useRef } from 'react';
import Modal from '../Modal';
import CardBinsForm from '../../forms/payment/CardBinsForm';
import { useDispatch, useSelector } from 'react-redux';
import {
  addCardBin,
  selectCardBinById,
  updateCardBin,
} from '../../../../redux/slices/payment/cardBinsSlice';

const AddCardBinModal = ({ id, setId }) => {
  const formRef = useRef();
  const dispatch = useDispatch();
  const cardBin = useSelector((state) => selectCardBinById(state, id));

  const handleRequest = async (data) => {
    const transformedData = {
      ...data,
      country: data.country?.name || '',
      iso2: data.iso2?.name || '',
      iso3: data.iso3?.name || '',
      iso_numeric: data.iso_numeric?.name || '',
    };

    if (id) {
      dispatch(updateCardBin({ id, data: { bin_card: transformedData } }));
    } else {
      dispatch(addCardBin({ bin_card: transformedData }));
    }
    formRef.current?.reset();
    setId(undefined);
    window.addCardBinModal.close();
  };

  return (
    <Modal
      id={'addCardBinModal'}
      title={!id ? 'Create Card BIN' : 'Edit Card BIN'}
      submitButtonTitle={!id ? 'Create' : 'Update'}
      onCancel={() => {
        formRef.current?.reset();
        window.addCardBinModal.close();
        setId(undefined);
      }}
      onClose={() => {
        formRef.current?.reset();
        window.addCardBinModal.close();
        setId(undefined);
      }}
      onSubmit={() => formRef.current?.submit()}
    >
      <CardBinsForm
        onSubmit={handleRequest}
        ref={formRef}
        cardBin={cardBin}
      />
    </Modal>
  );
};

export default AddCardBinModal;
