import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { handleAPI } from '../../../utils/api/api';

const initialState = {
  cardBins: [],
  totalPage: null,
  totalCount: null,
  count: null,
  isLoading: true,
};

export const getCardBins = createAsyncThunk(
  'cardBins/getCardBins',
  async (params) => {
    try {
      return handleAPI('platform/bin_cards', 'get', params);
    } catch {
      return [];
    }
  }
);

export const deleteCardBin = createAsyncThunk(
  'cardBins/deleteCardBin',
  async (id) => {
    return handleAPI(`platform/bin_cards/${id}`, 'delete', undefined);
  }
);

export const updateCardBin = createAsyncThunk(
  'cardBins/updateCardBin',
  async ({ id, data }) => {
    return handleAPI(`platform/bin_cards/${id}`, 'put', undefined, data);
  }
);

export const addCardBin = createAsyncThunk(
  'cardBins/addCardBin',
  async (data) => {
    return handleAPI('platform/bin_cards', 'post', undefined, data);
  }
);

export const selectCardBinById = (state, id) =>
  state.cardBins?.cardBins?.find((cardBin) => cardBin.id === id);

const cardBinsSlice = createSlice({
  name: 'cardBin',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getCardBins.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getCardBins.fulfilled, (state, action) => {
      state.isLoading = false;
      state.cardBins = action.payload;
      state.totalPage = action?.metaData?.total_pages;
      state.totalCount = action?.metaData?.total_count;
      state.count = action?.metaData?.count;
    });
    builder.addCase(getCardBins.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(deleteCardBin.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(deleteCardBin.fulfilled, (state, action) => {
      state.isLoading = false;
      if (action.payload?.status === 200) {
        state.cardBins = state.cardBins.filter(
          (cardBin) => cardBin.id !== action.meta.arg
        );
      }
    });
    builder.addCase(deleteCardBin.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(updateCardBin.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(updateCardBin.fulfilled, (state, action) => {
      state.isLoading = false;
      const updatedCardBin = action.payload;
      state.cardBins = state.cardBins.map((cardBin) =>
        cardBin.id === updatedCardBin.id ? updatedCardBin : cardBin
      );
    });
    builder.addCase(updateCardBin.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(addCardBin.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(addCardBin.fulfilled, (state, action) => {
      state.isLoading = false;
      if (!action.payload) {
        return;
      }
      state.cardBins.push(action.payload);
    });
    builder.addCase(addCardBin.rejected, (state) => {
      state.isLoading = false;
    });
  },
});

export default cardBinsSlice.reducer;
