import React, { useRef, useState } from 'react';
import { StaticDateTimePicker } from '@mui/x-date-pickers';
import DatePickerButton from '../../atoms/DatePickerButton/DatePickerButton';
import FilterModal from '../modal/FilterModal/FilterModal';
import { useId } from 'react';
import moment from 'moment';

const setReferenceDate = (startEndOfDay) => {
  if (startEndOfDay === 'start') {
    return moment().startOf('day');
  } else if (startEndOfDay === 'end') {
    return moment().endOf('day');
  }
  return undefined;
};

const DateRangePicker = ({
  value,
  onChange,
  minDate,
  maxDate,
  showTime = false,
  buttonClasses,
  startEndOfDay,
  startIcon = false,
  endIcon = false,
}) => {
  const [isMobile, setIsMobile] = useState(false);
  const id = useId();

  const ref = useRef(null);

  const handleButtonClick = (mobile) => {
    setIsMobile(mobile);
  };

  /*
  Sets the reference date and time, so you dont have to manually select the time, after you select the date
   */
  const referenceDate = setReferenceDate(startEndOfDay);

  const calendar = (
    <StaticDateTimePicker
      maxDate={maxDate}
      minDate={minDate}
      onChange={onChange}
      value={value}
      ampmInClock
      referenceDate={referenceDate}
      views={
        showTime
          ? ['year', 'month', 'day', 'hours', 'minutes']
          : ['year', 'month', 'day']
      }
      slots={{
        toolbar: null,
        shortcuts: null,
      }}
      slotProps={{
        layout: {
          className: `${!showTime && '[&_.MuiTabs-root]:hidden'}`,
        },
        actionBar: { actions: !isMobile ? ['clear'] : [] },
      }}
    />
  );

  return (
    <div className='!z-100 dropdown-end dropdown'>
      <div
        tabIndex={0}
        role='button'
      >
        <DatePickerButton
          className={`flex lg:hidden ${buttonClasses}`}
          placeholder={showTime ? 'mm/dd/yyy hh:mm' : 'mm/dd/yyyy'}
          isTime={showTime}
          value={value}
          onClick={() => handleButtonClick(true)}
          startIcon={startIcon}
          endIcon={endIcon}
        />
        <DatePickerButton
          className={`hidden lg:flex ${buttonClasses}`}
          placeholder={showTime ? 'mm/dd/yyy hh:mm' : 'mm/dd/yyyy'}
          isTime={showTime}
          value={value}
          onClick={() => handleButtonClick(false)}
          startIcon={startIcon}
          endIcon={endIcon}
        />
      </div>

      {isMobile ? (
        <FilterModal
          id={id}
          title='Select Date'
          submitButtonTitle='Apply'
          openOnLoad
          onCancel={() => onChange(null)}
          onClose={() => setIsMobile(false)}
        >
          {calendar}
        </FilterModal>
      ) : (
        <div
          ref={ref}
          tabIndex={0}
          onClick={() => ref.current.focus()}
          className='dropdown-content !z-[100] hidden lg:flex'
        >
          {calendar}
        </div>
      )}
    </div>
  );
};

export default DateRangePicker;
