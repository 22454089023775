import Cookies from 'js-cookie';
import AWS from 'aws-sdk';
import Jsona from 'jsona';
import moment from 'moment-timezone';
import fileDownload from 'js-file-download';
import { OUTLET_WRAPPER_ID } from 'constants/ids';
import { Navigate, useParams } from 'react-router-dom';
import React, { Suspense } from 'react';
import Loader from '../../components/atoms/loader/Loader';

const dataFormatter = new Jsona();

export const setCookie = (name, value) => {
  Cookies.set(name, value);
};

export const setAuthCookie = (name, value, rememberMe = false) => {
  if (rememberMe) {
    // Persistent cookie with expiration of 2 months
    let expires = new Date();
    let expirationTime = 3600 * 24 * 60;
    expires.setTime(expires.getTime() + expirationTime * 1000);
    Cookies.set(name, value, { expires });
  } else {
    // Just a simple session cookie
    Cookies.set(name, value);
  }
};

export const getCookie = (name) => {
  return Cookies.get(name);
};

export const removeCookie = (name) => {
  Cookies.remove(name);
};

export const deserializeData = (data) => {
  const response = dataFormatter.deserialize(data);
  return response;
};

export const uploadImageToS3 = async () => {
  const accessKeyId = process.env.REACT_APP_EXOSCALE_ACCESS_KEY_ID;
  const secretAccessKey = process.env.REACT_APP_EXOSCALE_SECRET_ACCESS_KEY;
  const enpoint = process.env.REACT_APP_EXOSCALE_ENDPOINT;
  const region = process.env.REACT_APP_EXOSCALE_REGION;

  AWS.config.update({
    accessKeyId: accessKeyId,
    secretAccessKey: secretAccessKey,
    region: region,
    endpoint: new AWS.Endpoint(enpoint),
    s3ForcePathStyle: true,
  });

  const s3 = new AWS.S3();
  return s3;
};

export const timeFormatter = (time, format = 'DD MMM YYYY HH:mm') => {
  if (time) {
    return moment.parseZone(time).format(format);
  } else {
    return '---';
  }
};

export const timeFormatterUtc = (time, format = 'DD MMM YYYY HH:mm') => {
  if (time) {
    return moment.utc(time).format(format);
  } else {
    return '---';
  }
};

export const capitalizeFirstLetter = (str) => {
  return str?.charAt(0).toUpperCase() + str?.slice(1).toLowerCase();
};

export const camelToTitleCase = (str) => {
  return (
    str?.slice(0, 1)?.toUpperCase() +
    str
      ?.slice(1)
      ?.replace(/([A-Z])/g, ' $1')
      .toLowerCase()
  );
};

export const getStatusColor = (status) => {
  switch (status) {
    case 'partial':
    case 'partial_refunded':
    case 'pending':
    case 'soft_decline':
    case 'processing':
    case 'hold':
    case 'rma_pending':
    case 'confirm_pending':
      return 'text-primary';

    case 'complete':
    case 'completed':
    case 'success':
    case 'shipped':
    case 'delivered':
    case 'active':
    case 'refunded':
    case 'true':
      return 'text-green';

    case 'declined':
    case 'failed':
    case 'hard_decline':
    case 'canceled':
    case 'inactive':
    case 'null':
      return 'text-danger';

    default:
      return '';
  }
};

export const formatStatus = (label) => {
  return label?.replace(/_/g, ' ');
};

export const removeEmptyData = (obj) => {
  if (obj && typeof obj === 'object') {
    Object.keys(obj).forEach((key) => {
      if (
        obj[key] &&
        typeof obj[key] === 'object' &&
        !Array.isArray(obj[key])
      ) {
        removeEmptyData(obj[key]);
      } else if (
        obj[key] === '' ||
        obj[key] === null ||
        obj[key] === undefined
      ) {
        delete obj[key];
      }
    });
  }
  return obj;
};

export const fileDownloader = (data, filename = 'report.csv') => {
  fileDownload(data, filename);
};

export const csvExport = (data, page) => {
  const datestamp = moment().format('YYYYMMDD_HHmmss');
  fileDownloader(data, `${page}_${datestamp}.csv`);
};

export const checkNestedValue = (abilities, key) => {
  const levels = key.split('.');
  let current = abilities;

  for (const level of levels) {
    if (!Object.prototype.hasOwnProperty.call(current, level)) {
      return false;
    }
    current = current[level];
  }

  const checkValue = (value) => {
    if (typeof value === 'boolean' && value) {
      return true;
    } else if (typeof value === 'object') {
      return Object.values(value).some((v) => checkValue(v));
    }
    return false;
  };

  return checkValue(current);
};

export const scrollToTop = (id = OUTLET_WRAPPER_ID) => {
  setTimeout(() =>
    document.getElementById(id)?.scrollTo({ top: 0, behavior: 'smooth' })
  );
};

/**
 * This function is a hack to solve the problem of deserialized objects becoming recursive, when using the
 * 'jsona' library in this project.
 * For example the corporation model includes relationship gateways model, which has a corporation relationship...
 * It's a quick hack to get around these edge cases.
 * @param {*} data
 * @param {string} objectKey
 */
export const sanitizeData = (data, objectKey) => {
  const traverseAndSanitize = (obj, depth = 0) => {
    if (typeof obj !== 'object' || obj === null) return obj;

    const sanitizedObj = Array.isArray(obj) ? [] : {};

    for (const key in obj) {
      if (key === objectKey && depth > 0) {
        sanitizedObj[key] = obj[key].map((corporation) => ({
          id: corporation.id,
          name: corporation.name,
        }));
      } else {
        sanitizedObj[key] = traverseAndSanitize(obj[key], depth + 1);
      }
    }

    return sanitizedObj;
  };

  return traverseAndSanitize(data);
};

export const convertToUserTimezone = (
  date,
  timezone = getCookie('timezone')
) => {
  if (!date) return null;

  const browserTimezone = moment.tz.guess();

  const dateInUserTimezone = moment.tz(date, timezone);
  const dateWithoutZone = dateInUserTimezone.format('YYYY-MM-DDTHH:mm:ss.SSS');
  const dateInBrowserTimezone = moment.tz(dateWithoutZone, browserTimezone);

  return dateInBrowserTimezone.toDate();
};

export const convertToSelectedTimezone = (
  date,
  timezone = getCookie('timezone')
) => {
  if (!date) return null;

  const browserTimezone = moment.tz.guess();

  const dateInBrowserTimezone = moment.tz(date, browserTimezone);
  const dateWithoutZone = dateInBrowserTimezone.format(
    'YYYY-MM-DDTHH:mm:ss.SSS'
  );
  const dateInDesiredTimezone = moment.tz(dateWithoutZone, timezone);

  return dateInDesiredTimezone.toDate();
};

export const transformNumberFieldValue = (value) =>
  isNaN(value) ? null : value;

export const addFilterField = (field, value, condition = true) => {
  return {
    ...(condition &&
      value !== undefined &&
      value !== null &&
      value !== '' &&
      value !== false && { [field]: value }),
  };
};

export const addDateFilterField = (field, value, dayBoundary) => {
  if (!value) {
    return undefined;
  }

  const adjustedValue = value.clone();

  if (dayBoundary === 'start') {
    adjustedValue.startOf('day');
  } else if (dayBoundary === 'end') {
    adjustedValue.endOf('day');
  }

  return addFilterField(field, adjustedValue.toISOString());
};

const ValidatedRoute = ({
  component: Component,
  validate,
  fallback = '/*',
}) => {
  const { number } = useParams();

  if (!validate(number)) {
    return (
      <Navigate
        to={fallback}
        replace={true}
      />
    );
  }

  return <Component />;
};

export const createValidatedRoute = (path, Component, validate, fallback) => ({
  path,
  element: (
    <Suspense fallback={<Loader spinner />}>
      <ValidatedRoute
        component={Component}
        validate={validate}
        fallback={fallback}
      />
    </Suspense>
  ),
});

export const isValidAlphanumericId = (id) => {
  const alphanumericRegex = /^[A-Za-z0-9]{12}$/;
  return alphanumericRegex.test(id);
};

export const getUrlInfo = () => {
  const url = window.location.href;

  return {
    protocol: url.split('//')[0],
    subdomain: url.split('//')[1].split('.')[0],
  };
};
