import React, { Suspense, lazy } from 'react';
import { Navigate, createBrowserRouter } from 'react-router-dom';
import paths from './paths';
import ErrorBoundary from '../components/pages/error/ErrorBoundary';
import { productRoutes } from './routes/productRoutes';
import { campaignRoutes } from './routes/campaignRoutes';
import { campaignEditRoutes } from './routes/campaignEditRoute';
import { adminRoutes } from './routes/adminRoutes';
import { paymentRoutes } from './routes/paymentRoutes';
import { orderRoutes } from './routes/orderRoutes';
import { accountRoutes } from './routes/accountRoutes';
import { customerRoutes } from './routes/customerRoutes';
import NotFound from '../components/pages/error/NotFound';
import HomeSkeleton from '../components/layout/home/HomeSkeleton';
import Loader from '../components/atoms/loader/Loader';

const LoginPage = lazy(() => import('../components/pages/auth/LoginPage'));
const ForgetPassword = lazy(
  () => import('../components/pages/auth/ForgetPassword')
);
const ResetPassword = lazy(
  () => import('../components/pages/auth/ResetPassword')
);
const Home = lazy(() => import('../components/layout/home/Home'));

const createLazyComponent = (Component) => (
  <Suspense fallback={<Loader spinner />}>
    <Component />
  </Suspense>
);

export const router = (role, abilities) => {
  const product = productRoutes(abilities?.products);
  const campaign = campaignRoutes(abilities?.campaigns);

  const getNavigateRoute = (role) => {
    switch (role) {
      case 'admin':
        if (abilities?.products?.product?.read ?? false)
          return paths.getProductLayout;
        else return paths.home;
      default:
        return paths.home;
    }
  };
  const createRoute = (path, Component) => ({
    path,
    element: createLazyComponent(Component),
  });
  const router = createBrowserRouter([
    {
      path: '/',
      element: (
        <Suspense fallback={<HomeSkeleton />}>
          <Home />
        </Suspense>
      ),
      errorElement: <ErrorBoundary />,
      children: [
        {
          index: true,
          element: <Navigate to={getNavigateRoute(role)} />,
        },
        ...product,
        ...campaign,
        ['admin'].includes(role) && campaignEditRoutes(abilities?.campaigns),
        ['admin'].includes(role) && paymentRoutes(abilities?.payments),
        [
          'admin',
          'customer_service_admin',
          'leads_agent',
          'sales_agent',
          'subaccount_user',
        ].includes(role) && adminRoutes(abilities),
        accountRoutes(abilities?.settings),
        ...customerRoutes(abilities?.customer),
        ...orderRoutes(abilities?.orders),
      ],
    },
    createRoute(paths.login, LoginPage),
    createRoute(paths.forgetPassword, ForgetPassword),
    createRoute(paths.resetPassword, ResetPassword),
    createRoute('/*', NotFound),
  ]);
  return router;
};
