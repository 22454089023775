import React from 'react';
import { useState } from 'react';
import useCommanForm from '../../../hooks/form/useCommanForm';
import Button from '../../atoms/button/Button';
import DatePicker from '../DateRangePicker/DateRangePicker';
import FilterBadgeList from '../../organism/FilterBadgeList/FilterBadgeList';
import Filterbar from '../Filterbar/Filterbar';
import { useMediaQuery } from '@mui/material';

const OrdersFilterBar = ({ FilterForm, onSubmit }) => {
  const { handleSubmit, register, reset, control, getValues, resetField } =
    useCommanForm();

  const [dateRange, setDateRange] = useState({
    startDate: null,
    endDate: null,
  });

  const [appliedFilters, setAppliedFilters] = useState({});

  const isMobile = useMediaQuery('(max-width: 812px)');

  const handleFormSubmit = (data, { reset } = { reset: false }) => {
    onSubmit({
      ...data,
      startDate: !reset ? dateRange.startDate : null,
      endDate: !reset ? dateRange.endDate : null,
      cardType: data.cardType?.name,
    });

    if (reset) setDateRange({ startDate: null, endDate: null });

    setAppliedFilters(getValues());
  };

  const handleCancel = () => {
    reset();
    handleSubmit(handleFormSubmit)({ reset: true });
  };

  const handleResetIndividualField = (key) => {
    resetField(key);
    handleSubmit(handleFormSubmit)();
  };

  return (
    <>
      <Filterbar
        onSubmit={handleSubmit(handleFormSubmit)}
        onCancel={handleCancel}
        onlyModal
      >
        <FilterForm
          register={register}
          control={control}
        />
      </Filterbar>

      <div className='flex w-full flex-col gap-3 lg:w-max lg:flex-row [&>*]:w-full lg:[&>*]:w-auto [&>div>div>button]:w-full'>
        <DatePicker
          value={dateRange.startDate}
          onChange={(startDate) => setDateRange({ ...dateRange, startDate })}
          showTime
          startEndOfDay={'start'}
          startIcon={isMobile}
        />

        <DatePicker
          value={dateRange.endDate}
          onChange={(endDate) => setDateRange({ ...dateRange, endDate })}
          showTime
          startEndOfDay={'end'}
          endIcon={isMobile}
        />
      </div>

      <Button
        title='Apply'
        type='submit'
        className='hidden lg:flex'
        onClickHandler={handleSubmit(handleFormSubmit)}
      />

      <FilterBadgeList
        filters={appliedFilters}
        onClear={handleResetIndividualField}
        onClearAll={handleCancel}
      />
    </>
  );
};

export default OrdersFilterBar;
