import React from 'react';
import CalendarIcon from '../../icons/CalendarIcon';
import EndIcon from '../../icons/EndIcon';
import StartIcon from '../../icons/StartIcon';

const DatePickerButton = ({
  value,
  onClick,
  placeholder = 'mm/dd/yyyy',
  isTime = false,
  className,
  startIcon = false,
  endIcon = false,
  ...props
}) => {
  const formatedValue = isTime
    ? value?.format('MM/DD/YYYY HH:mm A')
    : value?.format('L');
  const size = isTime
    ? 'w-full min-w-[15rem]'
    : 'min-w-[9rem] md:min-w-[10.625rem]';

  return (
    <button
      type='button'
      onClick={onClick}
      placeholder={placeholder}
      {...props}
      className={`group flex h-10 items-center gap-2 rounded-[5px] border border-gray-5 px-[0.625rem] py-3 text-sm text-text focus:!border-primary ${className} ${size}`}
    >
      <CalendarIcon className={`stroke-text group-focus:stroke-primary`} />
      {value ? (
        formatedValue
      ) : (
        <span className='text-gray-5'>{placeholder}</span>
      )}
      {startIcon && (
        <div className={'ml-auto text-text group-focus:text-primary'}>
          <StartIcon />
        </div>
      )}
      {endIcon && (
        <div className={'ml-auto text-text group-focus:text-primary'}>
          <EndIcon />
        </div>
      )}
    </button>
  );
};

export default DatePickerButton;
