import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  addDateFilterField,
  addFilterField,
  fileDownloader,
  timeFormatter,
} from '../../../../utils/helper/helper';
import RenderTable from '../../../molecule/table/RenderTable';
import TableCell from '../../../atoms/tableCell/TableCell';
import PageTitle from '../../../atoms/pageTitle/PageTitle';
import OrdersFilterBar from '../../../molecule/filterBars/OrdersFilterBar';
import TableRow from 'components/atoms/TableRow/TableRow';
import BinRoutingsTabGroup from '../../../organism/TabGroups/BinRoutingsTabGroup';
import AdminAffiliatesTabGroup from '../../../organism/TabGroups/AdminAffiliatesTabGroup';
import IntlBinsFilterForm from '../../../molecule/forms/payment/IntlBinsFilterForm';
import FileInputButton from '../../../atoms/FileInput/FileInputButton';
import { handleAPI } from '../../../../utils/api/api';
import { showToastr } from '../../../../redux/slices/toastrSlice';
import {
  deleteCardBin,
  getCardBins,
} from '../../../../redux/slices/payment/cardBinsSlice';
import Button from '../../../atoms/button/Button';
import PlusIcon from '../../../icons/PlusIcon';
import AddCardBinModal from '../../../molecule/modal/payment/AddCardBinModal';
import TableActionButtons from '../../../molecule/TableActionButtons/TableActionButtons';
import { openActionModal } from '../../../../redux/slices/confirmationModalSlice';

const CardBins = ({ prepaid = false }) => {
  const dispatch = useDispatch();
  const [pagination, setPagination] = useState({ currentPage: 1, perPage: 10 });
  const { currentPage, perPage } = pagination;
  const [filters, setFilters] = useState({});
  const { cardBins, totalPage, totalCount, isLoading } = useSelector(
    (state) => state.cardBins
  );
  const [selectedBin, setSelectedBin] = useState(undefined);

  const queryParams = useMemo(
    () => ({
      page: currentPage,
      per_page: perPage,
      'filter[s]': 'created_at desc',
      ...(prepaid ? { 'filter[prepaid_bin_card]': 'PREPAID' } : {}),
      ...filters,
    }),
    [currentPage, filters, perPage, prepaid]
  );

  useEffect(() => {
    dispatch(getCardBins(queryParams));
  }, [dispatch, queryParams]);

  const handleFilters = async (data) => {
    setPagination({ currentPage: 1, perPage: perPage });

    setFilters({
      'filter[s]': 'created_at desc',
      ...addDateFilterField('filter[created_at_gteq]', data.startDate),
      ...addDateFilterField('filter[created_at_lteq]', data.endDate),
      ...addFilterField('filter[bin_cont]', data.bin),
      ...addFilterField('filter[card_brand_cont]', data.card_brand),
      ...addFilterField('filter[card_type_cont]', data.card_type),
      ...addFilterField('filter[card_sub_type_cont]', data.card_sub_type),
      ...addFilterField(
        'filter[issuing_organisation_cont]',
        data.issuing_organisation
      ),
      ...addFilterField('filter[country_cont]', data.country),
      ...addFilterField('filter[iso2_cont]', data.iso2),
      ...addFilterField('filter[iso3_cont]', data.iso3),
      ...addFilterField('filter[iso_numeric_eq]', data.iso_numeric),
      ...(prepaid ? { 'filter[prepaid_bin_card]': 'PREPAID' } : {}),
    });
  };

  const handleDeleteBin = (id) => {
    dispatch(
      openActionModal({
        heading: `Are you sure to delete the BIN?`,
        action: () => dispatch(deleteCardBin(id)),
      })
    );
  };

  const uploadFile = async (file) => {
    if (file) {
      const formData = new FormData();
      formData.append('file', file);
      const response = await handleAPI(
        'platform/bin_cards/csv_import',
        'post',
        '',
        formData
      );
      if (response) {
        fileDownloader(response?.data?.log_file, 'intl_bins.csv');
        if (response.status === 202) {
          dispatch(
            showToastr({
              message: 'Import aborted. Check downloaded Log file for details.',
              visible: true,
              type: 'error',
            })
          );
        }
      }
    }
  };

  return (
    <div>
      <AddCardBinModal
        id={selectedBin}
        setId={setSelectedBin}
      />
      {prepaid ? <AdminAffiliatesTabGroup /> : <BinRoutingsTabGroup />}
      <div className='mt-[16px] flex flex-wrap items-center justify-end gap-x-3 gap-y-6'>
        <PageTitle
          title={prepaid ? 'Prepaid Bins' : 'International BINs'}
          className={'mr-auto'}
        />
        <FileInputButton
          title='Import CSV'
          id='file-upload-input'
          accept='.csv'
          handleFile={uploadFile}
          className='mr-5'
        />
        <OrdersFilterBar
          FilterForm={IntlBinsFilterForm}
          onSubmit={handleFilters}
        />
      </div>
      <div className={'mt-6 flex justify-between'}>
        <Button
          title={'Add BIN'}
          icon={<PlusIcon />}
          className={'ml-auto'}
          onClickHandler={() => window.addCardBinModal.showModal()}
        />
      </div>

      <RenderTable
        dataCount={cardBins?.length}
        columns={[
          'Bin',
          'Card Brand',
          'Card Type',
          'Card Subtype',
          'Issuing organisation',
          'Country',
          'Country ISO 2',
          'Country ISO 3',
          'Country ISO numeric',
          'Created At',
          'Updated At',
          'Actions',
        ]}
        totalPages={totalPage}
        totalCount={totalCount}
        pagination={pagination}
        compactPlaceholder
        setPagination={setPagination}
        isLoading={isLoading}
      >
        {cardBins?.map((cardBin) => (
          <TableRow key={cardBin?.id}>
            <TableCell>{cardBin?.bin}</TableCell>
            <TableCell>{cardBin?.card_brand}</TableCell>
            <TableCell>{cardBin?.card_type}</TableCell>
            <TableCell>{cardBin?.card_sub_type}</TableCell>
            <TableCell>{cardBin?.issuing_organisation}</TableCell>
            <TableCell>{cardBin?.country}</TableCell>
            <TableCell>{cardBin?.iso2}</TableCell>
            <TableCell>{cardBin?.iso3}</TableCell>
            <TableCell>{cardBin?.iso_numeric}</TableCell>
            <TableCell>{timeFormatter(cardBin?.created_at)}</TableCell>
            <TableCell>{timeFormatter(cardBin?.updated_at)}</TableCell>
            <TableActionButtons
              rowTitle={'entry'}
              onDeleteClick={() => handleDeleteBin(cardBin?.id)}
              onEditClick={() => {
                setSelectedBin(cardBin?.id);
                window.addCardBinModal.showModal();
              }}
              editPrivilege={'payments.bin_routing.write'}
              deletePrivilege={'payments.bin_routing.destroy'}
            />
          </TableRow>
        ))}
      </RenderTable>
    </div>
  );
};

export default CardBins;
